<div class="ig-composite-field" [class]="styleClass" [style]="style">
    <input class="ig-field-inner"
           [readOnly]="readonly"
           [disabled]="disabled"
           [value]="value"
           [showPassword]="showPlainText"
           [placeholder]="placeholder"
           (input)="onTextChange($event)"
           (blur)="onTextFieldBlur($event)"
           pPassword [feedback]="false"
           type="password"
           autocomplete="new-password"/>
    <button *ngIf="toggleButtonVisible" (click)="onToggleShowSecretButtonClick()"
            igButton
            class="ig-button"
            type="button"
            [icon] = "toggleButtonIconCls"
            [title]="toggleButtonTooltip">
    </button>
</div>

import {ChangeDetectorRef, Component} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {
    AbstractFormComponent,
    AjaxService,
    AppService,
    DateUtil,
    Download,
    DownloadService
} from "@cafe/web-ui-framework";
import {AlertService} from "@infogix/angular-ui-framework";
import {debounceTime, first, map} from "rxjs/operators";
import {Observable, timer} from 'rxjs';
import * as moment_ from 'moment';

const moment = moment_["default"];

@Component({
    selector: 'dqp-server-logs-dialog',
    templateUrl: './server-logs-dialog.component.html',
    styleUrls: ['./server-logs-dialog.component.scss']
})
export class ServerLogsDialogComponent extends AbstractFormComponent {
    // max date
    today: Date = new Date();

    // flag indicating if download started to prevent starting another one
    downloadStarted: boolean = false;

    // download phase
    phase: string = "";

    // download progress percent
    progressPercent: number = 0;

    constructor(protected fb: FormBuilder,
                protected cdr: ChangeDetectorRef,
                public ref: DynamicDialogRef,
                public dialogConfig: DynamicDialogConfig,
                private appService: AppService,
                private ajaxService: AjaxService,
                private alertService: AlertService,
                private downloadService: DownloadService) {
        super(fb, cdr);
        const currentTime = DateUtil.normalizeForTimeOnly(moment().minutes(0).seconds(0).milliseconds(0).toDate());
        const oneHourAgo = new Date(currentTime.getTime() - (60 * 60 * 1000));
        this.formGroup = this.fb.group({
            startDate: [this.today, [Validators.required, this.createStartDateValidator()]],
            startTime: [oneHourAgo, [Validators.required, this.createStartDateValidator()]],
            endDate: [this.today, [Validators.required, this.createEndDateValidator()]],
            endTime: [currentTime, [Validators.required, this.createEndDateValidator()]]
        });
    }

    protected initializeForm(): void {
        this.addSubscription(this.formGroup.valueChanges.pipe(debounceTime(200))
            .subscribe(value => {
                this.cdr.markForCheck();
            }));
    }

    public get startDate(): FormControl {
        return this.formGroup.get('startDate') as FormControl;
    }

    public get endDate(): FormControl {
        return this.formGroup.get('endDate') as FormControl;
    }

    public get startTime(): FormControl {
        return this.formGroup.get('startTime') as FormControl;
    }

    public get endTime(): FormControl {
        return this.formGroup.get('endTime') as FormControl;
    }

    private createStartDateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.isStartBeforeEndDateTime() ? null : {
                invalidStartDate: true
            };
        };
    }

    private createEndDateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.isStartBeforeEndDateTime() ? null : {
                invalidEndDate: true
            };
        };
    }

    private isStartBeforeEndDateTime(): boolean {
        let retValue = true;
        if (this.formGroup) {
            if (this.startDate.value != "" && this.endDate.value != "") {
                const startDate: Date = this.startDate.value;
                const startTime: Date = this.startTime.value;
                const endDate: Date = this.endDate.value;
                const endTime: Date = this.endTime.value;
                const startDateTime: Date = DateUtil.combineDateAndTime(startDate, startTime);
                const endDateTime: Date = DateUtil.combineDateAndTime(endDate, endTime);
                const mStart = moment(startDateTime).second(0).milliseconds(0);
                const mEnd = moment(endDateTime).second(0).milliseconds(0);
                retValue = (mStart.isBefore(mEnd));
            }
        }
        return retValue;
    }

    onCancelClick() {
        this.ref.close(null);
    }

    startDownload() {
        if (!this.downloadStarted) {
            this.downloadStarted = true;
            this.phase = $localize`Searching for log files to export...`;
            this.progressPercent = 0;

            const startDate: Date = this.startDate.value;
            const startTime: Date = this.startTime.value;
            const endDate: Date = this.endDate.value;
            const endTime: Date = this.endTime.value;
            const startDateTime: Date = DateUtil.combineDateAndTime(startDate, startTime);
            const endDateTime: Date = DateUtil.combineDateAndTime(endDate, endTime);
            this.initiateLogsDownload(startDateTime, endDateTime)
                .pipe(first())
                .subscribe(
                    {
                        next:(status: string) => {
                            this.checkStatus();
                        },
                        error:(error: any) => {
                            this.downloadStarted = false;
                            const errorMessage = error?.errors[0]?.message || 'Unknown error';
                            this.alertService.showError($localize`'Error initiating log files export: '` + errorMessage);
                        }
                    }
                );
        }
    }

    /**
     * Periodically checks the status of the phase in which log files are packaged into a zip file
     * @private
     */
    private checkStatus() {
        // create a timer which will fire every 3 seconds so we call to get status of export
        const timerObservable = timer(2000, 3000);
        const timerSubscription = timerObservable.subscribe(
                (val) => {
                    this.checkLogsDownloadStatus()
                        .pipe(first())
                        .subscribe(
                            {
                                next:(statusObj: any) => {
                                    // console.log('statusObj', statusObj);
                                    const status = statusObj.status;
                                    switch (status) {
                                        case 'EXPORTING':
                                            if (statusObj.exportedFiles > 0) {
                                                this.phase = $localize`Packaged ${statusObj.exportedFiles} of ${statusObj.totalFilesToExport} files...`;
                                                const phasePercent: number = (statusObj.totalFilesToExport > 0)
                                                    ? ((100 * statusObj.exportedFiles) / statusObj.totalFilesToExport) : 0;
                                                this.progressPercent = (phasePercent * 0.8);
                                                //console.log('phase percent ' + phasePercent + ' progressPercent ' + this.progressPercent);
                                            } else if (statusObj.totalFilesToExport > 0) {
                                                this.phase = $localize`Found ${statusObj.totalFilesToExport} log files to export...`;
                                            }
                                            break;
                                        case 'DOWNLOAD_READY':
                                            this.phase = $localize`Packaging complete.`;
                                            // complete this timer observable
                                            timerSubscription.unsubscribe();
                                            // start download of a file
                                            const downloadUrl = statusObj.fileDownloadUrl;
                                            this.downloadFile(downloadUrl);
                                            break;
                                        case 'ERROR':
                                            timerSubscription.unsubscribe();
                                            this.downloadStarted = false;
                                            this.alertService.showError($localize`'Error packaging log files into zip file: '` + statusObj.errorMsg);
                                            break;
                                    }
                                },
                                error:(error: any) => {
                                    timerSubscription.unsubscribe();
                                    this.downloadStarted = false;
                                    this.alertService.showError($localize`'Error packaging log files into zip file: '` + error.errors[0].message);
                                }
                            }
                        );
                });
    }

    /**
     * Downloads a zip file with server logs and updates status of download
     * @param downloadUrl
     * @private
     */
    private downloadFile(downloadUrl) {
        this.phase = $localize`Waiting for download to start...`;
        this.downloadService.download(downloadUrl, 'serverlogs.zip')
            .subscribe({
                next:(download: Download) => {
                    switch (download.state) {
                        case "PENDING":
                            // this.phase = $localize`Waiting for download to start...`;
                            break;
                        case "IN_PROGRESS":
                            this.phase = $localize`Downloading...`;
                            const phasePercent = download.progress;
                            this.progressPercent = 80 + (phasePercent * 0.2);
                            //console.log("progressPercent", this.progressPercent);
                            break;
                        case "DONE":
                            this.phase = $localize`Done`;
                            this.downloadStarted = false;
                            // close the dialog
                            this.ref.close(null);
                            break;
                    }
                },
                error:(error: any) => {
                    this.downloadStarted = false;
                    this.alertService.showError($localize`'Error downloading server logs zip: '` + error.message);
                }
            });
    }

    /**
     * Initiates creation of zip file with server logs between start and end date
     * @param startDateTime
     * @param endDateTime
     */
    initiateLogsDownload(startDateTime: Date, endDateTime: Date): Observable<string> {
        const startDate: string = DateUtil.toISO8601(startDateTime);
        const endDate: string = DateUtil.toISO8601(endDateTime);
        const query = `query {
                           exportServerLogs (input: {
                               startDateTime: "${startDate}",
                               endDateTime: "${endDate}"
                           })
                       }`;
        return this.ajaxService.query(query).pipe(
            map((response) => {
                    return response.data.exportServerLogs;
                }
            )
        );
    }

    /**
     * Checks status of the export of server logs into zip file
     */
    checkLogsDownloadStatus(): Observable<any> {
        const query = `query {
                           exportServerLogsStatus {
                               status
                               fileDownloadUrl
                               errorMsg
                               exportedFiles
                               totalFilesToExport
                           }
                       }`;
        return this.ajaxService.query(query).pipe(
            map((response) => {
                    return response.data.exportServerLogsStatus;
                }
            )
        );
    }

}

